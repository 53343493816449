.showcase {
    height: 696px;
    margin-top: 64px;
    margin-bottom: 64px;
}

@media (max-width: 640px) {
    .showcase {
        height: 432px;
        margin-top: 60px;
        margin-bottom: 30px;
    }
}
