.main-silhouette-container {
    position: relative;
    margin-bottom: 23px;
    margin-top: 26px;
}
@media (max-width: 580px) {
    .main-silhouette-container {
        width: 100%;
        height: 100%;
    }
}

.main-silhouette {
    height: 500px;
    width: 500px;
}
@media (max-width: 580px) {
    .main-silhouette {
        width: 100%;
        height: 100%;
    }
}

.main-oval {
    border: 1px solid #2CF6B3;
    border-radius: 50px;
    line-height: 46px;
    color: #2CF6B3;
    background: rgba(0, 0, 0, 0);
    font-weight: 700;
    max-width: 356px;
    width: 100%;
    margin: 6px 20px;
    text-align: center;
    cursor: pointer;
    font-size: 16px;
}
.main-oval::placeholder {
    color: #2CF6B3;
    text-align: center;
}

.main-oval.filled {
    background: #2CF6B3;
    color: #101419;
}
