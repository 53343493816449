.showcase-row {
    display: flex;
    flex-direction: row;
    position: relative;
    top: 116px;
    height: 232px;
}

@media (max-width: 640px) {
    .showcase-row {
        height: 144px;
    }
}
