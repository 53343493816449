.section-silhouette-punks h2 {
    margin-top: 48px;
    margin-bottom: 10px;
}

.section-silhouette-punks div {
    text-align: left;
    width: 100%;
    max-width: 610px;
    margin-top: 18px;
}

.section-silhouette-punks div:first-of-type {
    margin-top: 0;
}
