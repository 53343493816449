a, a:hover, a:focus, a:active {
    text-decoration: none;
    color: inherit;
}

body {
    margin: 0;
    font-family: "Cairo", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #101419;
    color: rgba(255, 255, 255, 0.75);
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    line-height: 26px;
    overflow-x: hidden;
}

header, h1, h2 {
    color: #ffffff;
    text-transform: uppercase;
    font-weight: bold;
}

header, h1 {
    font-size: 75px;
    letter-spacing: 0.15em;
}

h2 {
    font-size: 32px;
    line-height: 60px;
}

html {
    box-sizing: border-box;
}
*, *:before, *:after {
    box-sizing: inherit;
}

div#root {
    width: 100%;
    overflow-x: hidden;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

img {
    image-rendering: crisp-edges; /* fallback */
    image-rendering: pixelated;
}
