header {
    width: 100%;
    margin-top: 55px;
    line-height: 141px;
    white-space: nowrap;
}
@media (max-width: 890px) {
    header {
        margin-top: 30px;
        white-space: initial;
        line-height: 96px;
    }
}
@media (max-width: 580px) {
    header {
        font-size: 24px;
        line-height: 45px;
        margin-top: 15px;
    }
    .circle {
        height: 17px;
        width: 17px;
    }
}
.top-social {
    display: flex;
    justify-content: center;
    width: 100%;
    white-space: nowrap;
}
@media (max-width: 380px) {
    .top-social {
        flex-direction: column;
    }

    .top-social > * {
        margin: 4px;
    }
}
