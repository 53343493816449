/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* make sure to set some focus styles for accessibility */
:focus {
    outline: 0;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
    -webkit-appearance: none;
    -moz-appearance: none;
}

input[type=search] {
    -webkit-appearance: none;
    -moz-appearance: none;
    box-sizing: content-box;
}

textarea {
    overflow: auto;
    vertical-align: top;
    resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */

audio,
canvas,
video {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
    display: none;
    height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */

[hidden] {
    display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */

html {
    font-size: 100%; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */
    -ms-text-size-adjust: 100%; /* 2 */
}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */

a:focus {
    outline: thin dotted;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */

a:active,
a:hover {
    outline: 0;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */

img {
    border: 0; /* 1 */
    -ms-interpolation-mode: bicubic; /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */

figure {
    margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */

form {
    margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */

fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */

legend {
    border: 0; /* 1 */
    padding: 0;
    white-space: normal; /* 2 */
    *margin-left: -7px; /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */

button,
input,
select,
textarea {
    font-size: 100%; /* 1 */
    margin: 0; /* 2 */
    vertical-align: baseline; /* 3 */
    *vertical-align: middle; /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

button,
input {
    line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */

button,
select {
    text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button; /* 2 */
    cursor: pointer; /* 3 */
    *overflow: visible;  /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
    cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */

input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
    *height: 13px; /* 3 */
    *width: 13px; /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */

input[type="search"] {
    -webkit-appearance: textfield; /* 1 */ /* 2 */
    box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */

textarea {
    overflow: auto; /* 1 */
    vertical-align: top; /* 2 */
}

/**
 * Remove most spacing between table cells.
 */

table {
    border-collapse: collapse;
    border-spacing: 0;
}

html,
button,
input,
select,
textarea {
    color: #222;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

img {
    vertical-align: middle;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.chromeframe {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

a, a:hover, a:focus, a:active {
    text-decoration: none;
    color: inherit;
}

body {
    margin: 0;
    font-family: "Cairo", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #101419;
    color: rgba(255, 255, 255, 0.75);
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    line-height: 26px;
    overflow-x: hidden;
}

header, h1, h2 {
    color: #ffffff;
    text-transform: uppercase;
    font-weight: bold;
}

header, h1 {
    font-size: 75px;
    letter-spacing: 0.15em;
}

h2 {
    font-size: 32px;
    line-height: 60px;
}

html {
    box-sizing: border-box;
}
*, *:before, *:after {
    box-sizing: inherit;
}

div#root {
    width: 100%;
    overflow-x: hidden;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

img {
    image-rendering: crisp-edges; /* fallback */
    -ms-interpolation-mode: nearest-neighbor;
        image-rendering: -moz-crisp-edges;
        image-rendering: pixelated;
}

/* arabic */
@font-face {
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-display: fallback;
    src: url(https://fonts.gstatic.com/s/cairo/v14/SLXGc1nY6HkvalIkTpumxdt0UX8.woff2) format('woff2');
    unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC;
}
/* latin-ext */
@font-face {
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-display: fallback;
    src: url(https://fonts.gstatic.com/s/cairo/v14/SLXGc1nY6HkvalIvTpumxdt0UX8.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-display: fallback;
    src: url(https://fonts.gstatic.com/s/cairo/v14/SLXGc1nY6HkvalIhTpumxdt0.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-display: fallback;
    src: url(https://fonts.gstatic.com/s/cairo/v14/SLXGc1nY6HkvalIkTpumxdt0UX8.woff2) format('woff2');
    unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC;
}
/* latin-ext */
@font-face {
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-display: fallback;
    src: url(https://fonts.gstatic.com/s/cairo/v14/SLXGc1nY6HkvalIvTpumxdt0UX8.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-display: fallback;
    src: url(https://fonts.gstatic.com/s/cairo/v14/SLXGc1nY6HkvalIhTpumxdt0.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

footer {
    background: #000000;
    min-height: 159px;
    width: 100%;
    color: #FFFFFF;
}

.footer-content {
    margin: 0 auto;
    height: 100%;
    max-width: 1280px;
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 159px;
}
@media (max-width: 1120px) {
    .footer-content {
        flex-direction: column;
    }
}

.footer-content > * {
    height: 100%;
}

.footer-logo {
    flex: 1 1;
    font-size: 48px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    line-height: 60px;
    text-align: left;
}

@media (max-width: 1120px) {
    .footer-logo {
        text-align: center;
        font-size: 24px;
        line-height: 45px;
    }
    .in-footer .footer-circle {
        height: 17px;
        width: 17px;
    }
}

.footer-social {
    display: flex;
    flex-direction: row;
    margin: 20px 0;
    white-space: nowrap;
}
@media (max-width: 1120px) {
    .footer-social > * {
        margin: 0 6px;
    }
}

@media (max-width: 580px) {
    .footer-social {
        flex-direction: column;
    }

    .footer-social > * {
        margin: 4px;
    }
}

.social-email {
  font-size: 14px;
  margin-right: 10px;
  position: relative;
  top: 6px;
}

.key-details {
    margin-top: 54px;
    line-height: 60px;
    margin-bottom: 10px;
}

.key-details-grid {
    background: #040506;
    border-radius: 13px;
    padding: 40px 142px;
    width: 100%;
}
@media (max-width: 860px) {
    .key-details-grid {
        padding: 40px 10vw;
    }
}

.key-details-pair-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 40px;
}
.key-details-pair-container:last-child {
    margin-bottom: 0;
}

@media (max-width: 400px) {
    .key-details-pair-container {
        flex-direction: column;
        margin-bottom: 0;
        align-items: center;
    }
}

.key-details-column {
    width: 50%;
    min-height: 66px;
    text-align: left;
    padding-left: 13%;
}
.key-details-column:first-child {
    padding-left: 0;
}

@media (max-width: 400px) {
    .key-details-column {
        padding-left: 0;
        margin-bottom: 40px;
        max-width: 200px;
        width: 100%;
    }
}

.key-details-header {
    min-height: 26px;
    width: 100%;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    color: #FFFFFF;
}
.key-details-value {
    min-height: 40px;
    width: 100%;
    font-size: 32px;
    line-height: 40px;
    color: #2CF6B3;
    font-weight: bold;
}

.main-silhouette-container {
    position: relative;
    margin-bottom: 23px;
    margin-top: 26px;
}
@media (max-width: 580px) {
    .main-silhouette-container {
        width: 100%;
        height: 100%;
    }
}

.main-silhouette {
    height: 500px;
    width: 500px;
}
@media (max-width: 580px) {
    .main-silhouette {
        width: 100%;
        height: 100%;
    }
}

.main-oval {
    border: 1px solid #2CF6B3;
    border-radius: 50px;
    line-height: 46px;
    color: #2CF6B3;
    background: rgba(0, 0, 0, 0);
    font-weight: 700;
    max-width: 356px;
    width: 100%;
    margin: 6px 20px;
    text-align: center;
    cursor: pointer;
    font-size: 16px;
}
.main-oval::-webkit-input-placeholder {
    color: #2CF6B3;
    text-align: center;
}
.main-oval:-ms-input-placeholder {
    color: #2CF6B3;
    text-align: center;
}
.main-oval::placeholder {
    color: #2CF6B3;
    text-align: center;
}

.main-oval.filled {
    background: #2CF6B3;
    color: #101419;
}

.notifications {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 20px;
    right: 20px;
}
@media (max-width: 640px) {
    .notifications {
        width: 100%;
        padding-left: 40px;
    }
}

.notification {
    width: 359px;
    height: 76px;
    margin-bottom: 20px;

    background: #040506;
    box-shadow: 0 5px 23px rgba(0, 0, 0, 0.2);
    border-radius: 8px;

    display: flex;
    flex-direction: row;
    position: relative;
}

@media (max-width: 640px) {
    .notification {
        width: 100%;
        min-height: 76px;
    }
}

.notification-icon {
    height: 100%;
    padding: 0 16px;
    display: flex;
    align-items: center;
}
.notification-icon img {
    width: 32px;
    height: 32px;
}

.notification-icon.exclamation-mark img {
    width: 48px;
    height: 48px;
    image-rendering: auto;
    shape-rendering: auto;
}

.notification-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    text-align: left;

    font-size: 14px;
    line-height: 18px;
    padding-right: 16px;
}
.notification-text a {
    color: #2CF6B3;
    text-decoration: underline;
}

.spinner {
    border: 2px solid #000000;
    border-top: 2px solid #7F9AAB;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    -webkit-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite;
    position: absolute;
    left: 24px;
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes spin {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.close-icon {
    width: 12px;
    height: 12px;
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
}

.rarity h2 {
    margin-top: 54px;
    margin-bottom: 10px;
}

.rarity div {
    margin-top: 18px;
    text-align: left;
    width: 100%;
    max-width: 610px;
}

.rarity div:first-of-type {
    margin-top: 0;
}

.roadmap {
    margin-top: 54px;
}

.roadmap-complete {
    line-height: 34px;
    font-size: 18px;
}

.roadmap-item {
    border: 1px solid #2CF6B3;
    border-radius: 100px;
    padding: 10px 10px 10px 60px;
    line-height: 54px;
    font-weight: bold;
    font-size: 32px;
    margin-top: 31px;
    text-align: left;
    color: #2CF6B3;
    white-space: nowrap;
    display: flex;
    align-items: center;
    width: 100%;
}

.progress-bar {
    padding: 10px 20px;
    background: #FFFFFF;
    border-radius: 100px;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
    margin-right: 20px;
}

.complete-icon {
    margin-left: 12px;
    width: 28px;
    height: 28px;
    display: inline-block;
    image-rendering: auto;
    shape-rendering: auto;
}

@media (max-width: 580px) {
    .roadmap-item {
        font-size: 18px;
        padding: 10px 10px 10px 30px;
    }

    .complete-icon {
        margin-left: 6px;
        width: 16px;
        height: 16px;
    }

    .progress-bar {
        padding: 10px 20px;
        background: #FFFFFF;
        border-radius: 100px;
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        margin-right: 10px;
    }
}

@media (max-width: 350px) {
    .progress-bar {
        display: none;
    }
}

.showcase {
    height: 696px;
    margin-top: 64px;
    margin-bottom: 64px;
}

@media (max-width: 640px) {
    .showcase {
        height: 432px;
        margin-top: 60px;
        margin-bottom: 30px;
    }
}

.showcase-row {
    display: flex;
    flex-direction: row;
    position: relative;
    top: 116px;
    height: 232px;
}

@media (max-width: 640px) {
    .showcase-row {
        height: 144px;
    }
}

.showcase-img {
    width: 200px;
    height: 200px;
    margin: 16px;
}

@media (max-width: 640px) {
    .showcase-img {
        width: 120px;
        height: 120px;
        margin: 12px;
    }
}

.app {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 864px;
    margin: 0 auto;
    width: 100%;
    padding: 0 20px;
}

.circle, .footer-circle {
    border-radius: 50%;
    border-width: 0;
    height: 53px;
    width: 53px;
    content: 'o';
    display: inline-block;
    margin-right: 0.15em;
}
.footer-circle {
    height: 36px;
    width: 36px;
}

.button {
    border: 1px solid #FFFFFF;
    border-radius: 100px;
    display: block;
    line-height: 32px;
    padding: 0 24px;
    margin-right: 10px;
    font-size: 14px;
}

.green-text {
    color: #2CF6B3;
}

.nowrap {
    white-space: nowrap;
}

.section-silhouette-punks h2 {
    margin-top: 48px;
    margin-bottom: 10px;
}

.section-silhouette-punks div {
    text-align: left;
    width: 100%;
    max-width: 610px;
    margin-top: 18px;
}

.section-silhouette-punks div:first-of-type {
    margin-top: 0;
}

header {
    width: 100%;
    margin-top: 55px;
    line-height: 141px;
    white-space: nowrap;
}
@media (max-width: 890px) {
    header {
        margin-top: 30px;
        white-space: normal;
        white-space: initial;
        line-height: 96px;
    }
}
@media (max-width: 580px) {
    header {
        font-size: 24px;
        line-height: 45px;
        margin-top: 15px;
    }
    .circle {
        height: 17px;
        width: 17px;
    }
}
.top-social {
    display: flex;
    justify-content: center;
    width: 100%;
    white-space: nowrap;
}
@media (max-width: 380px) {
    .top-social {
        flex-direction: column;
    }

    .top-social > * {
        margin: 4px;
    }
}

